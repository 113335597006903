<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <div class="nk-split nk-split-page nk-split-md">
        <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
          <div class="absolute-top-right d-lg-none p-3 p-sm-5">
            <router-link to="/"  class="toggle btn-white btn btn-icon btn-light" data-target="athPromo">
              <nio-icon icon="ni-info"></nio-icon>
            </router-link>
          </div>
          <div class="nk-block nk-block-middle nk-auth-body">
            <div class="brand-logo pb-5">
              <brand-logo></brand-logo>
            </div>
            <div class="nk-block-head">
              <div class="nk-block-head-content">
                <h5 class="nk-block-title">Sign-In</h5>
                <div class="nk-block-des">
                  <p>Access your leads management dashboard by authenticating yourself.</p>
                </div>
              </div>
            </div><!-- .nk-block-head -->

            <form v-on:submit.prevent="doLogin(user_email, user_pwd)">
              <form-group>
                <div class="form-label-group">
                  <label class="form-label" for="default-01">Email{{email}}</label>
                </div>
                <input type="text" class="form-control form-control-lg" id="default-01" placeholder="Enter your email address" v-model="user_email">
              </form-group>
              <form-group>
                <div class="form-label-group">
                  <label class="form-label" for="password">Password</label>
                  <a class="link link-primary link-sm" tabindex="-1" href="#">Forgot Code?</a>
                </div>
                <div class="form-control-wrap">
                  <a tabindex="-1" href="javascript:;" class="form-icon form-icon-right passcode-switch" v-on:click="showPwd=!showPwd">
                    <nio-icon icon="ni ni-eye" v-if="!showPwd"></nio-icon>
                    <nio-icon icon="ni-eye-off" v-else></nio-icon>
                  </a>
                  <input :type="showPwd ? 'text' : 'password'" class="form-control form-control-lg" id="password" placeholder="Enter your password" v-model="user_pwd">
                </div>
              </form-group>
              <form-group>
                <form-submit-button text="Login"></form-submit-button>
              </form-group>
            </form><!-- form -->
            <div class="form-note-s2 pt-4"> New on our platform? <router-link :to="{name: 'Register'}" :key="'ww'+11">Create an account</router-link>
            </div>
            <div class="text-center pt-4 pb-3">
              <h6 class="overline-title overline-title-sap"><span>OR</span></h6>
            </div>
            <ul class="nav justify-center gx-4">
<!--              <li class="nav-item"><a class="nav-link" href="#">Facebook</a></li>-->
              <li class="nav-item"><a class="nav-link" href="javascript:;" v-on:click="signInWithGoogle">Google</a></li>
            </ul>
          </div><!-- .nk-block -->
          <auth-footer></auth-footer>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, loadingController, toastController, alertController } from "@ionic/vue"
import { BrandLogo } from "@core/components"
import { FormGroup } from "@core/layouts"
import FormSubmitButton from "@/views/auth/components/FormSubmitButton";
import store from "@/store"
import { useRouter } from "vue-router"
import {ref, defineComponent} from "vue";
import NioIcon from "@core/components/nio-icon/NioIcon";
import useGoogleSignIn from "@/libs/login-with-google";
import axios from "@/libs/axios"
import AuthFooter from "@/views/auth/components/AuthFooter";
import authFunctions from "./index";

export default defineComponent({
  components: {AuthFooter, NioIcon, FormSubmitButton, BrandLogo, FormGroup, IonPage, IonContent},
  setup(){

    let user_email = ref('')
    let user_pwd   = ref('')
    let showPwd    = ref(false)

    const router = useRouter()

    // Sign in with email & password
    const {doLogin} = authFunctions()

    // Sign in with google
    let auth2;
    const { init } = useGoogleSignIn()
    init().then( auth => {
      auth2 = auth
      auth.isSignedIn.listen(onGoogleSignIn);
    })
    .catch()
    const signInWithGoogle = async () => {
      // User is already logged in
      if(auth2.isSignedIn.get()){
        auth2.disconnect()
      }
      auth2.signIn();
    }

    const onGoogleSignIn = async (signedIn) => {
      if(signedIn){
        const loader = await loadingController.create({message: 'Signing in...'})
        await loader.present()
        // disconnect the google user,
        // so it does not automatically sign in on the next Login page reload.
        auth2.disconnect()
        const authRep = auth2.currentUser.get().getAuthResponse()
        let params = {
          id_token: authRep.id_token,
          client_id: process.env.VUE_APP_API_CLIENT_ID,
          client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
        }
        axios.post('/api/advisor/sign_with_google', params)
            .then( async resp => {
              if(resp.data.access_token){
                store.commit('auth/AUTH_SUCCESS', resp.data)
                localStorage.setItem('access_token', resp.data.access_token)
                localStorage.setItem('refresh_token', resp.data.refresh_token)
                store.dispatch('notifications/getNotifications')
                store.dispatch('get_logged_in_user_profile', {role: resp.data.role})
                await store.dispatch('get_general_data')
                await router.push( resp.data.role && resp.data.role === 'is-admin' ? '/admin/dashboard' : '/')
              }
              else{
                if(!['SILENT'].includes(resp.data.message)){
                  toastController.create({message: resp.data.message, duration:3000, color: 'danger'}).then((a)=> a.present())
                }
              }
            })
            .catch( error => {
              alertController.create({message: 'Error: '+error.response.statusText, buttons: ['OK']}).then(( a )=> a.present())
            })
            .then( () => loader.dismiss())
      }
    }

    const changeLang = (lang) => {
      store.dispatch('appConfig/change_language', lang)
      window.location.reload()
    }

    return {
      doLogin,
      user_email,
      user_pwd,
      showPwd,
      router,

      signInWithGoogle,
      changeLang,
      store,
    }
  },
})
</script>
